import React from 'react'
import styles from './cookiePolicy.module.scss'
import EmptyBlock from '../Header/emptyBlock'


export default class CookiePolicy extends React.Component {
  //   handleCloseFeatures = () => {
  //     // document.getElementById('drop-down').classList.remove('drop-down-active');
  //     document.getElementById('drop-down-pricing').classList.remove('drop-down-active');
  //     document.getElementById('overlay').removeAttribute('class');
  //     document.body.removeAttribute('class');
  // }
  render() {
    return (
      <>
        <EmptyBlock />
        {/* <div onMouseEnter={this.handleCloseFeatures} id='overlay'></div> */}
        <div className={styles.main}>
          <div className={styles.content}>
            <h1> How We Use Cookie Files</h1>
            <h2>Our website uses cookie files to differentiate you from other users who visit the DueFocus website.</h2>
            <p>This helps us provide you with a better interaction with the website and make improvements to the website.</p>
            <p>We also use cookie files in marketing emails (which will be delivered to you if you gave prior consent to this method of communication), to understand how our users respond to our marketing practices. This helps us build them according to your needs and improve our communication. We provide a list of specific cookie files that we use below.</p>
          </div>
          <div className={styles.content}>
            <h2> What Are Cookie Files And How Do They Work?</h2>
            <p>Cookie files are data fragments that websites transfer to your hard drive for storage, sometimes to gather certain information about you. Most browsers automatically accept cookie files, however, you can change your browser settings to prevent this from happening. This can be done via the help window or by following directions. However, after declining cookie files, you will not be able to use all the advantages and features available on our website.</p>
            <p>Cookie files are unique for the server which generated them and other servers can not access them. This means that cookie files can’t be used to track your browsing activities throughout the Internet.</p>
            <p>Even though these files help identify the computer and/or device of a particular user, they do not identify them personally, store passwords, or credit card information.</p>
            <p>You can learn more about cookie files at <a rel="noopener noreferrer" target='_blank' href='https://www.aboutcookies.org/'>www.aboutcookies.org</a></p>
          </div>
          <div className={styles.content}>
            <h2> What Kind Of Cookies Do We Use?</h2>
            <p>We use the following types of cookie files:</p>
            <p>Strictly Necessary cookie files: These files are required to make our website work correctly and correspond to the agreement made between us. These cookie files include, for example, files that allow users to see protected pages of our website, use our shopping cart, and use our billing module.</p>
            <p>Performance cookie files: These cookie files allow us to recognize and count the number of viewers and visitors of our website and understand how they browse our website. This helps us improve the functionality of our website by, for example, making it easier for users to find the information they are looking for.</p>
            <p>Functionality cookie files: These cookie files are used to recognize you when you return to our website. This helps us to refer to you by your name and personalize your website experience based on previously collected preferences (such as language, region, and others).</p>
            <p>Targeting or Advertising cookie files: These cookie files record data about your visit to the DueFocus website, the pages you browsed, and links you clicked on. We need information about your settings and preferences to make sure that advertising on our website corresponds to your interests. We can also share this information with third parties.</p>
          </div>
          <div className={styles.content}>
            <h2> Where Can You Learn More About The Cookie Files We Use? When Do Our Cookie Expire?</h2>
            <p>Other than Strictly Necessary cookies, all files used by our website expire no later than 2 years after they are recorded.</p>
          </div>
          <div className={styles.content}>
            <h2> What Happens If You Turn Off Cookie Files?</h2>
            <p>The consequences of turning off cookie files for our website depends on which kind of cookies you turn off. However, in most cases users’ actions will be limited.</p>
            <p>Our website foresees the use of a plugin that will let you turn cookies on and off. However, you can also turn off cookie files in your browser settings by following the instructions below:</p>
            <h3>To disable cookies in Chrome:</h3>
            <p>Follow these instructions: <a rel="noopener noreferrer" target='_blank' href='https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en'>https://support.google.com/</a></p>
            <h3>To disable cookies in Safari:</h3>
            <p>Follow these instructions: <a rel="noopener noreferrer" target='_blank' href='https://support.apple.com'>https://support.apple.com/</a></p>
            <h3>To disable cookies in Firefox:</h3>
            <p>Follow these instructions: <a rel="noopener noreferrer" target='_blank' href='https://support.mozilla.org'>https://support.mozilla.org/</a></p>
            <h3>To disable cookies in other browsers:</h3>
            <p>Please refer to the official browser documentation for instructions.</p>
          </div>
          <div className={styles.content}>
            <h2>Cookie File Tips</h2>
            <p>If you disable cookies that are critical to the performance of our website we will remind you about this.</p>
          </div>
          <div className={styles.content}>
            <h2> How To Learn More </h2>
            <p>If you would like to learn more about the way our websites use cookies, please email us at: <span>privacy@diligences.com</span></p>
          </div>
        </div>
      </>
    )
  }
}
