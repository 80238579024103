import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import CookiePolicy from '../components/CookiePolicy/cookies-policy'
import { Helmet } from "react-helmet";

// const content = `We use cookies to distinguish you from the other visitors of DueFocus website. This helps us to provide and improve interaction with the website.`
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const Cookie = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>Cookie Policy - duefocus</title>
      <meta name="description" content={content} />
      <meta name="robots" content="noindex, nofollow" />
      <link rel="canonical" href="https://www.duefocus.com/cookies-policy/" />
    </Helmet>
    <Header />
    <CookiePolicy />
    <Footer />
  </>
)

export default Cookie